import { configureStore } from '@reduxjs/toolkit'
import userReducer from './userSlice';
import logger from 'redux-logger';

const store = configureStore({
    reducer: {
        user: userReducer
    },
    
    middleware: process.env.NODE_ENV === 'development' 
        ? (getDefaultMiddleware) => getDefaultMiddleware().concat(logger) 
        : undefined,
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;