import 'index.css';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import theme from "./theme";
import reduxStore from 'redux/store';
import { Provider as  ReduxProvider } from 'react-redux';
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import atBoxRouter from 'pages/atBoxRouter';

const router = createBrowserRouter(atBoxRouter);

const root = ReactDOM.createRoot(
  document.getElementById('atbox') as HTMLElement
);

root.render(
  <ThemeProvider theme={theme}>
    <ReduxProvider store={reduxStore}>
      <Suspense fallback={<div>loading...</div>}>
        <RouterProvider router={router} />
      </Suspense>
    </ReduxProvider>
  </ThemeProvider>
);
