import { createTheme } from '@mui/material/styles';

export default createTheme({
  palette: {
    primary: {
      main: "#FF7F00",        
    },
    secondary: {
      main: '#1E0FC8',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        filledPrimary: {
          color: 'white'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: 'white'
        },
        contained: {
          minWidth: '88px'
        }
      },
      variants: [
        {
          props: { variant: 'cancel' },
          style: {
            backgroundColor: '#CACCCF',
            color: 'white',
            minWidth: '88px',
            '&:hover': {
              backgroundColor: '#A8A8A8',
            }
          },
        },
      ],
    }
  }
});

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    cancel: true;
  }
}