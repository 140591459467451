import { lazy } from 'react';

const Dashboard = lazy(() => import( "./dashboard"));
const BoxAdminErrorPage = lazy(() => import( "./ErrorPage"));
const UnderConstruction = lazy(() => import( "./underConstruction"));
const SchedulePreset = lazy(() => import( "./schedule/Preset"));
const Schedule = lazy(() => import('./schedule/Schedule'));
const BoxInfo = lazy(() => import( "./settings/BoxInfo"));
const BoxEdit = lazy(() => import( "./settings/BoxEdit"));
const RoomEdit = lazy(() => import( "./settings/RoomEdit"));
const ClassManagerPage = lazy(() => import( "./settings/ClassManagerPage"));
const MembershipSettingPage = lazy(() => import( "./settings/MembershipSettingPage"));
const UpdatePassword = lazy(() => import( "./settings/UpdatePassword"));

const RootContainer = lazy(() => import('./RootContainer'));
const Members = lazy(() => import('./member/Members'));
const MemberDetail = lazy(() => import('./member/MemberDetail'));
const UserMembershipDetail = lazy(() => import('./member/UserMembershipDetail'));
const HoldingRequestList = lazy(() => import('./holding_request'));
const Login = lazy(() => import('./login'));
const UserMembershipExpireDateBulkUpdate = lazy(() => import('./member/UserMembershipExpireDateBulkUpdate'));

const AdminPartnerLogin = lazy(() => import('./admin_only/PartnerLogin'));
const AdminPartnerManagement = lazy(() => import('./admin_only/PartnerManagement'));
const AdminBoxGroupMemberManagement = lazy(() => import('./admin_only/BoxGroupMemberManagement'));

const NoticeList = lazy(() => import('./settings/Notice/NoticeList'));
const NoticeEditor = lazy(() => import('./settings/Notice/NoticeEditor'));
const NoticeDetail = lazy(() => import('./settings/Notice/NoticeDetail'));
const UserCheckIn = lazy(() => import('./user_check_in'));

const SalesStatus = lazy(() => import('./reports/SalesStatus'));
const TodayAttendance = lazy(() => import('./attendance/TodayAttendance'));
const MonthlyAttendance = lazy(() => import('./attendance/MonthlyAttendance'));

const AdminThrowdownList = lazy(() => import('./admin_only/throwdown/ThrowdownList'));
const AdminThrowdownDetail = lazy(() => import('./admin_only/throwdown/ThrowdownDetail'));
const AdminThrowdownBoxDetail = lazy(() => import('./admin_only/throwdown/ThrowdownBoxDetail'));
const AdminThrowdownCompetitionDetail = lazy(() => import('./admin_only/throwdown/ThrowdownCompetitionDetail'));

const MemberJoinRequest = lazy(() => import('./member_join_request'));
const DropinRequestList = lazy(() => import('./dropin-request'));
const FreeTrialRequestList = lazy(() => import('./free-trial-request'));

const ManagerList = lazy(() => import('./manager/ManagerList'));

const ThrowdownList = lazy(() => import('./throwdown/ThrowdownList'));
const ThrowdownParticipantList = lazy(() => import('./throwdown/ThrowdownParticipantList'));
const ThrowdownQualifierEventDetail = lazy(() => import('./throwdown/ThrowdownQualifierEventDetail'));
const ThrowdownQualifierTotalRank = lazy(() => import('./throwdown/ThrowdownQualifierTotalRank'));

const TestPage = lazy(() => import('./TestPage'));

const atBoxRouter = [
    {
        path: '/',
        element: <RootContainer />,
        errorElement: <BoxAdminErrorPage />,
        children: [
            {
                path: 'dashboard',
                element: <Dashboard />,
            },
            {
                path: 'schedule',
                element: <Schedule />,
            },
            {
                path: 'schedule/preset',
                element: <SchedulePreset />,
            },
            {
                path: 'dropin-requests',
                element: <DropinRequestList />,
            },
            {
                path: 'free-trial-requests',
                element: <FreeTrialRequestList />,
            },
            {
                path: 'members',
                element: <Members />,
            },
            {
                path: 'members/:memberId',
                element: <MemberDetail />,
            },
            {
                path: 'members/:memberId/user-memberships/:userMembershipId',
                element: <UserMembershipDetail />,
            },
            {
                path: 'user-memberships/expire-date/bulk-update',
                element: <UserMembershipExpireDateBulkUpdate />,
            },
            {
                path: 'member-join-requests',
                element: <MemberJoinRequest />,
            },
            {
                path: 'holding-requests',
                element: <HoldingRequestList />,
            },
            {
                path: 'coach/management',
                element: <UnderConstruction />,
            },
            {
                path: 'coach/register',
                element: <UnderConstruction />,
            },
            {
                path: 'reports/sales-status',
                element: <SalesStatus />,
            },
            {
                path: 'attendance/today',
                element: <TodayAttendance />,
            },
            {
                path: 'attendance/montly',
                element: <MonthlyAttendance />,
            },
            {
                path: 'managers',
                element: <ManagerList />,
            },
            {
                path: 'settings/box-info',
                element: <BoxInfo />,
            },
            {
                path: 'settings/box-info/box/create',
                element: <BoxEdit />,
            },
            {
                path: 'settings/box-info/boxes/:boxId/edit',
                element: <BoxEdit />,
            },
            {
                path: 'settings/box-info/boxes/:boxId/edit-room',
                element: <RoomEdit />,
            },
            {
                path: 'settings/class',
                element: <ClassManagerPage />,
            },
            {
                path: 'settings/membership',
                element: <MembershipSettingPage />,
            },
            {
                path: 'settings/notices',
                element: <NoticeList />,
            },
            {
                path: 'settings/notices/:noticeId',
                element: <NoticeDetail />,
            },
            {
                path: 'settings/notices/:noticeId/edit',
                element: <NoticeEditor />,
            },
            {
                path: 'settings/notice/create',
                element: <NoticeEditor />,
            },
            {
                path: 'settings/update-password',
                element: <UpdatePassword />,
            },
            {
                path: 'under-construction',
                element: <UnderConstruction />,
            },
            {
                path: 'throwdowns',
                element: <ThrowdownList />,
            },
            {
                path: 'throwdowns/:throwdownId/participants',
                element: <ThrowdownParticipantList />,
            },
            {
                path: 'throwdowns/:throwdownId/qualifier-events/:qualifierEventId',
                element: <ThrowdownQualifierEventDetail />,
            },
            {
                path: 'throwdowns/:throwdownId/qualifier-total-rank',
                element: <ThrowdownQualifierTotalRank />,
            },

            // Admin only
            {
                path: 'admin/partner-login',
                element: <AdminPartnerLogin />,
            },
            {
                path: 'admin/partner-management',
                element: <AdminPartnerManagement />,
            },
            {
                path: 'admin/box-group-member-management',
                element: <AdminBoxGroupMemberManagement />,
            },
            {
                path: 'admin/throwdowns',
                element: <AdminThrowdownList />,
            },
            {
                path: 'admin/throwdowns/:throwdownId',
                element: <AdminThrowdownDetail />,
            },
            {
                path: 'admin/throwdowns/:throwdownId/boxes/:boxId',
                element: <AdminThrowdownBoxDetail />,
            },
            {
                path: 'admin/throwdowns/:throwdownId/competitions/:competitionId',
                element: <AdminThrowdownCompetitionDetail />,
            }
        ]
    },
    {
        path: "/user-check-in",
        element: <UserCheckIn />,
    },
    {
        path: "/login",
        element: <Login />,
        errorElement: <BoxAdminErrorPage />,
    },
    {
        path: '/test',
        element: <TestPage />,
    }
];


export default atBoxRouter;