import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MyInfoData } from 'types/user';
import { BoxGroupInfo } from 'types/box';

export enum UserState {
  loading,
  login,
  logout
};

const initialState: {
  userInfo: MyInfoData | null,
  userState: UserState
} = {
  userInfo: null,
  userState: UserState.loading
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout(state) {
      state.userInfo = null;
      state.userState = UserState.logout;
    },
    login(state, action: PayloadAction<MyInfoData>) {
      state.userInfo = action.payload;
      state.userState = UserState.login;
    },
    addOrUpdateBoxGroup(state, action: PayloadAction<BoxGroupInfo>) {
      if (state.userInfo === null) {
        return;
      }

      state.userInfo.boxGroup = action.payload;
    }
  },
});

export const { logout, login, addOrUpdateBoxGroup } = userSlice.actions;
export default userSlice.reducer;